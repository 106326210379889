import React, { useContext, useEffect, useState } from 'react';
import ABeagleContext from '../../contexts/abeagle';
import { adShieldScript } from '@buzzfeed/bf-utils';



function AdShieldScript() {
  const { getFeatureFlagValue } = useContext(ABeagleContext);
  const isEnabled = getFeatureFlagValue('RT-1559-AdShield-script-on-BFDC');
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (isEnabled && !isLoaded) {
      setIsLoaded(true);
      adShieldScript.init({
        isShopping: false,
        destination: 'buzzfeed'
      });
    }
  }, [isEnabled]);

  return (
    <></>
  );
}

export default AdShieldScript;
