import React from 'react';
import PropTypes from 'prop-types';
import FeedCard from '../../../FeedCard';
import styles from './shoppingPost.module.scss';
import ImagePlaceholder from '../../../../static/img/image-placeholder.svg';
import { buildOriginUrl } from '../../../../utils/data';

export const ShoppingPost = ({ item, index, trackingData }) => {
  const { content, id: contentObjectId, object_type: objectType } = item;

  const { category = {}, thumbnail = {}, url = '', title = '', id = '', trackingData: algorithmTrackingData = {} } = content;
  const urlWithOrigin = buildOriginUrl(url);

  const feedCardUrl = thumbnail?.square?.url || thumbnail?.standard?.url || ImagePlaceholder;
  const feedCardAltText = thumbnail?.square?.alt || thumbnail?.standard?.alt || '';

  const subunitTrackingData = {
    subunit_type: 'component',
    subunit_name: `${objectType}|${contentObjectId}`,
  };

  const sharedTrackingData = {
    ...subunitTrackingData,
    ...algorithmTrackingData,
    item_type: 'card',
    item_name: id,
    position_in_subunit: index,
    target_content_type: 'buzz',
    target_content_id: id,
    ...trackingData, // should be last to make sure custom overrides take precedence
  };
  const categoryTrackingData = {
    ...sharedTrackingData,
    item_type: 'text',
    item_name: category?.label?.toLowerCase(),
    target_content_type: 'feed',
    target_content_id: 6,
  };

  if (!id?.length) {
    return null;
  }

  return (
    <FeedCard className={styles.shoppingPost} trackingData={sharedTrackingData} isTrackable={true}>
      <FeedCard.Link url={urlWithOrigin} isTrackable={true} trackingData={sharedTrackingData}>
        <figure>
          <FeedCard.Thumbnail
            thumbnail={feedCardUrl}
            alt={String(feedCardAltText)}
            lazyLoadThumbnail={true}
            ratioTablet = "1/1"
          />
        </figure>
      </FeedCard.Link>
      <div>
        <FeedCard.Link
          url={category.url}
          isTrackable={true}
          trackingData={categoryTrackingData}
        >
          <span className={styles.itemLabel}>{category.label}</span>
        </FeedCard.Link>
        <FeedCard.Link url={urlWithOrigin} isTrackable={true} trackingData={sharedTrackingData}>
          <h3>{title}</h3>
        </FeedCard.Link>
      </div>
    </FeedCard>
  );
};

ShoppingPost.propTypes = {
  data: PropTypes.shape({
    category: PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
    thumbnail: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })
};

export default ShoppingPost;
